import React, { useState, useEffect, useRef } from "react";
import VoucherEntryField from "@carfax/web-ui/components/VoucherEntryField";
import { styles } from "./VoucherField.styles";
import browserStore from "stores/browser.store";
import { PaymentsApi } from "utils/backend-api/payment/payments-api";
import { ApiConfig } from "utils/backend-api/api-config";

import { useStores } from "hooks";
import { withRecaptcha } from "domain/Recaptcha/withRecaptcha";
import RecaptchaActions from "domain/Recaptcha/actions";
import { dataLayerEvents } from "domain/SeoAndAnalytics/legacy/GoogleAnalytics/utils";
import { EHttpStatus } from "utils/backend-api/error-types";
import BackendApiError from "utils/backend-api/backend-api-error";
import NoSSR from "@carfax/web-ui/elements/NoSSR";
import { useTranslation } from "app";

export const VOUCHER_RECAPTCHA_ID = "voucher-recaptcha";

interface VoucherFieldProps {
  setShouldRenderDropIn: (value) => void;
}

const VoucherField: React.FC<VoucherFieldProps> = ({ setShouldRenderDropIn }) => {
  const { checkoutStore } = useStores();
  const [voucherErrorMsg, setVoucherErrorMsg] = useState("");
  const [voucherCheckPending, setVoucherCheckPending] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation(["page-pay", "forms"]);

  const submitVoucher = async (voucherCode: string, useRef) => {
    if (!voucherCode || voucherErrorMsg || !checkoutStore.transactionId || voucherCheckPending) {
      return;
    }

    if (alreadyDiscounted()) {
      setVoucherErrorMsg("formErrors.voucher.transactionAlreadyDiscounted");
      return;
    }

    const paymentApi = new PaymentsApi(ApiConfig.buildBackendUrlForClient());
    const trimVoucherCode = voucherCode.trim();
    setVoucherCheckPending(true);

    try {
      const { discountedPrice, usedCode, discountPercent } = await withRecaptcha({
        action: RecaptchaActions.voucher,
        captchaId: VOUCHER_RECAPTCHA_ID,
      })((tokens) =>
        paymentApi.putVoucherCode(
          checkoutStore.transactionId,
          {
            voucherCode: trimVoucherCode,
          },
          tokens
        )
      );
      setVoucherErrorMsg(null);
      checkoutStore.updateProduct({ priceWithVoucher: discountedPrice, voucherCode: usedCode });
      if (isVoucherFullDiscount(discountedPrice)) {
        setShouldRenderDropIn(false);
      } else {
        setShouldRenderDropIn(true);
      }
      dataLayerEvents.submitGenericButtonVoucherEvent(usedCode, discountPercent);
    } catch (error) {
      if (error instanceof BackendApiError) {
        if (error.status === EHttpStatus.Forbidden) {
          setVoucherErrorMsg("formErrors.voucher.recaptcha");
        } else {
          setVoucherErrorMsg(error.msg);
        }
      }
      if (inputRef && !checkoutStore.voucherCodeFromQuery.voucherCode) {
        useRef.focus();
      }
    } finally {
      setVoucherCheckPending(false);
    }
  };

  const handleChange = () => {
    setVoucherErrorMsg(null);
  };

  const isVoucherFullDiscount = (discountedPrice: number) => {
    return discountedPrice === 0;
  };

  const alreadyDiscounted = () => {
    return checkoutStore.product.basePrice && checkoutStore.product.price < checkoutStore.product.basePrice;
  };

  useEffect(() => {
    if (checkoutStore.voucherCodeFromQuery.isDiscounted) {
      submitVoucher(checkoutStore.voucherCodeFromQuery.voucherCode, inputRef);
    }
  }, []);

  return (
    <NoSSR>
      <VoucherEntryField
        initiallyCollapsed={!browserStore.isDesktop}
        cardTitle={t("page-pay:pay.voucher.headline")}
        collapseButtonTestId="collapse-voucher-btn"
        inputProps={{
          submitButtonTestId: "submit-voucher-btn",
          inputName: "voucher-input",
          placeholderMessage: `${t("pay.voucher.inputText")}`,
          onSubmit: (voucherCode, inputRef) => submitVoucher(voucherCode, inputRef.current),
          onChange: () => handleChange(),
          onBlur: (event) => submitVoucher(event.target.value, event.target),
          error: voucherErrorMsg ? t("forms:" + voucherErrorMsg) : "",
          defaultValue: checkoutStore.voucherCodeFromQuery.voucherCode,
        }}
      />
      <div id={VOUCHER_RECAPTCHA_ID} className="g-recaptcha" css={styles.recaptcha} />
    </NoSSR>
  );
};

export default VoucherField;
